import { db } from '../firebase';
import { auth } from '../firebase'; // Import auth
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  addDoc, 
  doc, 
  updateDoc, 
  limit, 
  writeBatch,
  getDoc
} from 'firebase/firestore';
// Instead of using authService, let's adapt to use the current user setup
// For now, we'll use a simpler approach without user authentication

// Get the current user ID from Firebase Auth
const getUserId = () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated. Please sign in to manage collections.');
  }
  return user.uid;
};

// Get all collections for the current user
export const getCollections = async () => {
  const userId = getUserId();
  
  try {
    const collectionsRef = collection(db, 'collections');
    const collectionsQuery = query(
      collectionsRef,
      where('userId', '==', userId),
      orderBy('updatedAt', 'desc')
    );
    
    const snapshot = await getDocs(collectionsQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting collections:', error);
    return [];
  }
};

// Create a new collection
export const createCollection = async (name, description = '') => {
  const userId = getUserId();
  
  try {
    const now = new Date();
    const collectionsRef = collection(db, 'collections');
    const docRef = await addDoc(collectionsRef, {
      name,
      description,
      userId,
      isDefault: false,
      createdAt: now,
      updatedAt: now
    });
    
    return docRef.id;
  } catch (error) {
    console.error('Error creating collection:', error);
    throw error;
  }
};

// Update a collection's metadata
export const updateCollection = async (collectionId, updates) => {
  try {
    const now = new Date();
    const collectionRef = doc(db, 'collections', collectionId);
    await updateDoc(collectionRef, {
      ...updates,
      updatedAt: now
    });
    return true;
  } catch (error) {
    console.error('Error updating collection:', error);
    throw error;
  }
};

// Delete a collection and all its items
export const deleteCollection = async (collectionId) => {
  try {
    const batch = writeBatch(db);
    
    // Delete the collection document
    const collectionRef = doc(db, 'collections', collectionId);
    batch.delete(collectionRef);
    
    // Find and delete all collection items
    const collectionItemsRef = collection(db, 'collection_items');
    const itemsQuery = query(
      collectionItemsRef,
      where('collectionId', '==', collectionId)
    );
    const itemsSnapshot = await getDocs(itemsQuery);
      
    itemsSnapshot.docs.forEach(docSnapshot => {
      const itemRef = doc(db, 'collection_items', docSnapshot.id);
      batch.delete(itemRef);
    });
    
    // Commit the batch
    await batch.commit();
    return true;
  } catch (error) {
    console.error('Error deleting collection:', error);
    throw error;
  }
};

// Add a thought to a collection
export const addThoughtToCollection = async (collectionId, thoughtId) => {
  try {
    // Check if item already exists
    const collectionItemsRef = collection(db, 'collection_items');
    const existingItemQuery = query(
      collectionItemsRef,
      where('collectionId', '==', collectionId),
      where('thoughtId', '==', thoughtId),
      limit(1)
    );
    const existingItem = await getDocs(existingItemQuery);
      
    if (!existingItem.empty) {
      console.log('Thought already in collection');
      return true;
    }
    
    // Get the highest position to add at the end
    const positionQuery = query(
      collectionItemsRef,
      where('collectionId', '==', collectionId),
      orderBy('position', 'desc'),
      limit(1)
    );
    const positionSnapshot = await getDocs(positionQuery);
      
    let position = 0;
    if (!positionSnapshot.empty) {
      position = positionSnapshot.docs[0].data().position + 1;
    }
    
    // Add the new item
    await addDoc(collectionItemsRef, {
      collectionId,
      thoughtId,
      position,
      addedAt: new Date()
    });
    
    // Update the collection's updatedAt timestamp
    const collectionRef = doc(db, 'collections', collectionId);
    await updateDoc(collectionRef, {
      updatedAt: new Date()
    });
    
    return true;
  } catch (error) {
    console.error('Error adding thought to collection:', error);
    throw error;
  }
};

// Remove a thought from a collection
export const removeThoughtFromCollection = async (collectionId, thoughtId) => {
  try {
    const collectionItemsRef = collection(db, 'collection_items');
    const itemsQuery = query(
      collectionItemsRef,
      where('collectionId', '==', collectionId),
      where('thoughtId', '==', thoughtId)
    );
    const itemsSnapshot = await getDocs(itemsQuery);
      
    if (itemsSnapshot.empty) {
      console.log('Thought not found in collection');
      return true;
    }
    
    // Delete the items
    const batch = writeBatch(db);
    itemsSnapshot.docs.forEach(docSnapshot => {
      const itemRef = doc(db, 'collection_items', docSnapshot.id);
      batch.delete(itemRef);
    });
    await batch.commit();
    
    // Update the collection's updatedAt timestamp
    const collectionRef = doc(db, 'collections', collectionId);
    await updateDoc(collectionRef, {
      updatedAt: new Date()
    });
    
    return true;
  } catch (error) {
    console.error('Error removing thought from collection:', error);
    throw error;
  }
};

// Get all thoughts in a collection
export const getCollectionThoughts = async (collectionId) => {
  try {
    // Get the collection items sorted by position
    const collectionItemsRef = collection(db, 'collection_items');
    const itemsQuery = query(
      collectionItemsRef,
      where('collectionId', '==', collectionId),
      orderBy('position')
    );
    const itemsSnapshot = await getDocs(itemsQuery);
      
    if (itemsSnapshot.empty) {
      return [];
    }
    
    // Extract thought IDs
    const thoughtIds = itemsSnapshot.docs.map(doc => doc.data().thoughtId);
    
    // Instead of batch queries, fetch each thought individually with error handling
    const thoughts = [];
    
    // Fetch each thought - if one fails, we'll still get the others
    await Promise.all(thoughtIds.map(async (thoughtId) => {
      try {
        const thoughtDoc = await getDoc(doc(db, 'thoughts', thoughtId));
        
        if (thoughtDoc.exists()) {
          thoughts.push({
            id: thoughtDoc.id,
            ...thoughtDoc.data()
          });
        }
      } catch (error) {
        console.log(`Skipping thought ${thoughtId} due to access error`);
        // Just skip this thought - we'll return what we can access
      }
    }));
    
    // Return thoughts in original order as much as possible
    return thoughtIds
      .map(id => thoughts.find(t => t.id === id))
      .filter(Boolean); // Remove nulls (thoughts we couldn't access)
  } catch (error) {
    console.error('Error getting collection thoughts:', error);
    return [];
  }
};

// Create the default "Your Thoughts" collection if it doesn't exist
export const ensureDefaultCollection = async () => {
  const userId = getUserId();
  
  try {
    // Check if a default collection exists
    const collectionsRef = collection(db, 'collections');
    const defaultCollectionQuery = query(
      collectionsRef,
      where('userId', '==', userId),
      where('isDefault', '==', true),
      limit(1)
    );
    const snapshot = await getDocs(defaultCollectionQuery);
      
    if (snapshot.empty) {
      // Create the default collection
      const now = new Date();
      await addDoc(collectionsRef, {
        name: 'Your Thoughts',
        description: 'All your thoughts',
        userId,
        isDefault: true,
        createdAt: now,
        updatedAt: now
      });
    }
    
    return true;
  } catch (error) {
    console.error('Error ensuring default collection:', error);
    return false;
  }
};

// Get all collections that contain a specific thought
export const getCollectionsForThought = async (thoughtId) => {
  try {
    // First get all collection items for this thought
    const collectionItemsRef = collection(db, 'collection_items');
    const itemsQuery = query(
      collectionItemsRef,
      where('thoughtId', '==', thoughtId)
    );
    const itemsSnapshot = await getDocs(itemsQuery);
    
    if (itemsSnapshot.empty) {
      return [];
    }
    
    // Extract unique collection IDs
    const collectionIds = [...new Set(
      itemsSnapshot.docs.map(doc => doc.data().collectionId)
    )];
    
    // Batch query collections by ID
    const CHUNK_SIZE = 10; // Firestore limitation
    let collections = [];
    
    // Process collectionIds in chunks
    for (let i = 0; i < collectionIds.length; i += CHUNK_SIZE) {
      const chunk = collectionIds.slice(i, i + CHUNK_SIZE);
      
      // Create a query for this chunk of IDs
      const collectionsRef = collection(db, 'collections');
      const batchQuery = query(collectionsRef, where('__name__', 'in', chunk));
      const batchSnapshot = await getDocs(batchQuery);
      
      // Add all collections from this batch
      collections = [
        ...collections,
        ...batchSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      ];
    }
    
    // Sort by default first, then by name
    return collections.sort((a, b) => {
      if (a.isDefault && !b.isDefault) return -1;
      if (!a.isDefault && b.isDefault) return 1;
      return a.name.localeCompare(b.name);
    });
    
  } catch (error) {
    console.error('Error getting collections for thought:', error);
    return [];
  }
};
