import React, { useState } from 'react';
import { styles, colors } from '../styles/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import SortDrawer from './SortDrawer';
import FilterDrawer from './FilterDrawer';

/**
 * Clean, minimal Spotify-style feed for thoughts
 */
const ThoughtsList = ({ thoughts, title, onDeleteThought, showBackButton = false, onBackClick, onAddClick }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('importance');
    const [showSortDrawer, setShowSortDrawer] = useState(false);
    const [showFilterDrawer, setShowFilterDrawer] = useState(false);
    const [activeFilters, setActiveFilters] = useState({ tags: [] });
    
    // Calculate popular tags directly in the render phase instead of using useEffect
    // Extract and count all tags
    const tagCounts = {};
    thoughts.forEach(thought => {
        if (thought.tags && Array.isArray(thought.tags)) {
            thought.tags.forEach(tag => {
                tagCounts[tag] = (tagCounts[tag] || 0) + 1;
            });
        }
    });
    
    // Convert to array and sort by count (descending)
    const popularTags = Object.entries(tagCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([tag, count]) => ({ tag, count }))
        .slice(0, 50); // Top 50 tags

    // Toggle tag selection
    const toggleTag = (tag) => {
        setActiveFilters(prev => {
            const isTagActive = prev.tags.includes(tag);
            
            if (isTagActive) {
                // Remove tag if already active
                return {
                    ...prev,
                    tags: prev.tags.filter(t => t !== tag)
                };
            } else {
                // Add tag if not active
                return {
                    ...prev,
                    tags: [...prev.tags, tag]
                };
            }
        });
    };

    // First filter out thoughts that don't belong in the library (those without theme, motif, or tags)
    const libraryThoughts = thoughts.filter(thought => 
        thought.theme || thought.motif || (thought.tags && thought.tags.length > 0)
    );

    // Filter based on active tag filters
    const tagFilteredThoughts = libraryThoughts.filter(thought => {
        // If no filters are applied, show all thoughts
        if (activeFilters.tags.length === 0) {
            return true;
        }

        // Only show thoughts that have at least one matching tag
        return thought.tags && 
               Array.isArray(thought.tags) && 
               thought.tags.some(tag => activeFilters.tags.includes(tag));
    });

    // Then filter based on search term
    const filteredThoughts = tagFilteredThoughts.filter(thought => {
        const searchLower = searchTerm.toLowerCase();
        return (
            thought.thought?.toLowerCase().includes(searchLower) ||
            (thought.theme && thought.theme.toLowerCase().includes(searchLower)) ||
            (thought.motif && thought.motif.toLowerCase().includes(searchLower)) ||
            (thought.tags && thought.tags.some(tag => tag.toLowerCase().includes(searchLower)))
        );
    });

    // Sort thoughts based on selected criteria
    const sortedThoughts = [...filteredThoughts].sort((a, b) => {
        if (sortBy === 'recent') {
            // Sort by timestamp (newest first)
            return new Date(b.timestamp) - new Date(a.timestamp);
        } else if (sortBy === 'importance') {
            // Sort by importance (highest first), then by timestamp for equal importance
            const importanceA = a.importance !== undefined ? a.importance : 0;
            const importanceB = b.importance !== undefined ? b.importance : 0;
            
            if (importanceB !== importanceA) {
                return importanceB - importanceA;
            } else {
                // Secondary sort by timestamp
                return new Date(b.timestamp) - new Date(a.timestamp);
            }
        }
        return 0;
    });

    // Handle sort change
    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
    };

    // Handle filter change
    const handleFilterChange = (newFilters) => {
        setActiveFilters(newFilters);
    };

    // Check if any filters are active
    const hasActiveFilters = activeFilters.tags.length > 0;



    return (
        <div style={{ width: '100%' }} className="smooth-scroll">
            {/* CSS for responsive layouts only - no animations */}
            <style>
                {`
                    @media (min-width: 768px) {
                        .desktop-layout { display: flex; }
                        .mobile-layout { display: none; }
                    }
                    @media (max-width: 767px) {
                        .desktop-layout { display: none; }
                        .mobile-layout { display: flex; }
                    }
                `}
            </style>
            
            {/* DESKTOP LAYOUT */}
            <div className="desktop-layout" style={{
                flexDirection: 'column',
                width: '100%',
            }}>
                {/* Header bar with back button, title, search and tools */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '16px',
                    padding: '0 16px',
                    height: '38px'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px'
                    }}>
                        {showBackButton && (
                            <button onClick={onBackClick} style={{
                                background: 'none',
                                border: 'none',
                                padding: '4px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        )}
                        
                        <h2 style={{
                            margin: 0,
                            fontSize: '22px',
                            fontWeight: '600',
                            color: colors.textPrimary,
                            lineHeight: '38px',
                        }}>
                            {title || "Library"}
                        </h2>
                    </div>
                    
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        maxWidth: '500px',
                        width: '60%',
                        height: '38px'
                    }}>
                        {/* Search bar */}
                        <div style={{
                            position: 'relative',
                            flex: 1,
                            height: '38px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search thoughts..."
                                style={{
                                    ...styles.input,
                                    padding: '8px 12px 8px 36px',
                                    fontSize: '14px',
                                    borderRadius: '20px',
                                    backgroundColor: colors.surfaceElevated,
                                    border: 'none',
                                    color: colors.textPrimary,
                                    width: '100%',
                                    height: '32px',
                                }}
                            />
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    position: 'absolute',
                                    left: '12px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    color: colors.textSecondary
                                }}
                            >
                                <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        
                        {/* Tools */}
                        <div style={{ 
                            display: 'flex', 
                            gap: '4px',
                            height: '38px',
                            alignItems: 'center'
                        }}>
                            {/* Sort button */}
                            <button
                                onClick={() => setShowSortDrawer(true)}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: '4px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '32px',
                                    height: '32px'
                                }}
                            >
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 6H14M4 12H11M4 18H8M17 8L20 5M20 5L23 8M20 5V19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                            
                            {/* Filter button */}
                            <button
                                onClick={() => setShowFilterDrawer(true)}
                                aria-label="Filter"
                                style={{
                                    background: hasActiveFilters ? `${colors.accent.blue}20` : 'none',
                                    border: 'none',
                                    borderRadius: '4px',
                                    padding: '4px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '32px',
                                    height: '32px',
                                    position: 'relative'
                                }}
                            >
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke={hasActiveFilters ? colors.accent.blue : colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                
                                {/* Indicator dot for active filters */}
                                {hasActiveFilters && (
                                    <span style={{
                                        position: 'absolute',
                                        top: '-2px',
                                        right: '-2px',
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: colors.accent.blue,
                                    }}></span>
                                )}
                            </button>
                            
                            {/* Add button - Only shows if onAddClick prop is provided */}
                            {onAddClick && (
                                <button
                                    onClick={onAddClick}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        padding: '4px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '32px',
                                        height: '32px'
                                    }}
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5V19M5 12H19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="butt" strokeLinejoin="miter"/>
                                    </svg>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            
            {/* MOBILE LAYOUT */}
            <div className="mobile-layout" style={{
                flexDirection: 'column',
                width: '100%',
            }}>
                {/* Back button row */}
                    {showBackButton && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 16px',
                        marginBottom: '8px',
                    }}>
                        <button onClick={onBackClick} style={{
                            background: 'none',
                            border: 'none',
                            padding: '4px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18L9 12L15 6" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>
                )}
                
                {/* Search and tools row */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 16px',
                    marginBottom: '12px',
                }}>
                    {/* Search bar */}
                    <div style={{
                        position: 'relative',
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search thoughts..."
                            style={{
                                ...styles.input,
                                padding: '8px 12px 8px 36px',
                                fontSize: '14px',
                                borderRadius: '20px',
                                backgroundColor: colors.surfaceElevated,
                                border: 'none',
                                color: colors.textPrimary,
                                width: '100%',
                                height: '32px',
                            }}
                        />
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                position: 'absolute',
                                left: '12px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: colors.textSecondary
                            }}
                        >
                            <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    
                    {/* Tools */}
                    <div style={{ display: 'flex', gap: '4px', marginLeft: '8px' }}>
                        {/* Sort button */}
                        <button
                            onClick={() => setShowSortDrawer(true)}
                            style={{
                                background: 'none',
                                border: 'none',
                                padding: '4px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6H14M4 12H11M4 18H8M17 8L20 5M20 5L23 8M20 5V19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        
                        {/* Filter button */}
                        <button
                            onClick={() => setShowFilterDrawer(true)}
                            aria-label="Filter"
                            style={{
                                background: hasActiveFilters ? `${colors.accent.blue}20` : 'none',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '4px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative'
                            }}
                        >
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke={hasActiveFilters ? colors.accent.blue : colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            
                            {/* Indicator dot for active filters */}
                            {hasActiveFilters && (
                                <span style={{
                                    position: 'absolute',
                                    top: '-2px',
                                    right: '-2px',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    backgroundColor: colors.accent.blue,
                                }}></span>
                            )}
                        </button>
                    </div>
                </div>

                {/* Title and plus button row */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 16px',
                    marginBottom: '16px',
                }}>
                    <h2 style={{
                        margin: 0,
                        fontSize: '22px',
                        fontWeight: '600',
                        color: colors.textPrimary,
                        lineHeight: '38px',
                    }}>
                        {title || "Library"}
                    </h2>
                    
                    {/* Add button - Only shows if onAddClick prop is provided */}
                    {onAddClick && (
                        <button
                            onClick={onAddClick}
                        style={{
                                background: 'none',
                            border: 'none',
                                padding: '4px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '32px',
                                height: '32px'
                            }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5V19M5 12H19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="butt" strokeLinejoin="miter"/>
                    </svg>
                        </button>
                    )}
                </div>
            </div>

            {/* Horizontal scrollable tag filters */}
            {popularTags.length > 0 && (
                <div style={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    padding: '8px 16px',
                    marginTop: '24px',
                    WebkitOverflowScrolling: 'touch', /* For smooth scrolling on iOS */
                    scrollbarWidth: 'none', /* Hide scrollbar for Firefox */
                    msOverflowStyle: 'none', /* Hide scrollbar for IE/Edge */
                }}>
                    <style>
                        {`
                            /* Hide scrollbar for Chrome/Safari */
                            div::-webkit-scrollbar {
                                display: none;
                            }
                        `}
                    </style>
                    <div style={{
                        display: 'inline-flex',
                        gap: '8px',
                        paddingBottom: '4px', /* Extra padding to prevent cut-off */
                    }}>
                        {popularTags.map(({ tag }) => {
                            const isActive = activeFilters.tags.includes(tag);
                            return (
                                <button
                                    key={tag}
                                    onClick={() => toggleTag(tag)}
                                    style={{
                                        backgroundColor: isActive ? colors.accent.blue : `${colors.accent.blue}10`,
                                        color: isActive ? 'white' : colors.accent.blue,
                                        border: 'none',
                                        borderRadius: '16px',
                                        padding: '8px 14px',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        whiteSpace: 'nowrap',
                                        transition: 'all 0.2s cubic-bezier(0.2, 0, 0, 1)',
                                    }}
                                >
                                    {tag}
                                </button>
                            );
                        })}
                            
                        {/* Clear All button - only visible when there are active filters */}
                        {activeFilters.tags.length > 0 && (
                            <button 
                                onClick={() => setActiveFilters({ tags: [] })}
                                style={{
                                    backgroundColor: 'rgba(255, 59, 48, 0.1)',
                                    color: '#FF3B30',
                                    border: 'none',
                                    borderRadius: '16px',
                                    padding: '8px 14px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    transition: 'all 0.2s cubic-bezier(0.2, 0, 0, 1)',
                                }}
                            >
                                Clear All
                            </button>
                        )}
                    </div>
                </div>
            )}

            {/* No results state */}
            {(searchTerm || hasActiveFilters) && filteredThoughts.length === 0 ? (
                <div style={{ 
                    textAlign: 'center', 
                    padding: '40px 0', 
                    color: colors.textSecondary,
                    marginTop: '16px'
                }}>
                    <p>No thoughts match your search or filters.</p>
                </div>
            ) : (
                /* Clean, full-width feed */
                <div className="thoughts-feed" style={{ 
                    display: 'flex', 
                    flexDirection: 'column'
                }} key={activeFilters.tags.join(',') + searchTerm}>
                    <style>
                        {`
                            @keyframes fadeIn {
                                from { opacity: 0; transform: translateY(8px); }
                                to { opacity: 1; transform: translateY(0); }
                            }
                            
                            .thoughts-feed > div {
                                opacity: 0;
                                animation-name: fadeIn;
                                animation-duration: 0.3s;
                                animation-fill-mode: forwards;
                                animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
                            }
                            
                            .thoughts-feed > div:nth-child(1) { animation-delay: 0.05s; }
                            .thoughts-feed > div:nth-child(2) { animation-delay: 0.1s; }
                            .thoughts-feed > div:nth-child(3) { animation-delay: 0.15s; }
                            .thoughts-feed > div:nth-child(4) { animation-delay: 0.2s; }
                            .thoughts-feed > div:nth-child(5) { animation-delay: 0.25s; }
                            .thoughts-feed > div:nth-child(6) { animation-delay: 0.3s; }
                            .thoughts-feed > div:nth-child(7) { animation-delay: 0.35s; }
                            .thoughts-feed > div:nth-child(8) { animation-delay: 0.4s; }
                            .thoughts-feed > div:nth-child(9) { animation-delay: 0.45s; }
                            .thoughts-feed > div:nth-child(10) { animation-delay: 0.5s; }
                            .thoughts-feed > div:nth-child(n+11) { animation-delay: 0.5s; }
                        `}
                    </style>
                    {sortedThoughts.map((thought, index) => (
                        <FeedItem 
                            key={thought.id} 
                            thought={thought} 
                            isLast={index === sortedThoughts.length - 1}
                            onDelete={() => onDeleteThought && onDeleteThought(thought.id)}
                        />
                    ))}
                </div>
            )}

            {/* Sort drawer component */}
            <SortDrawer
                isVisible={showSortDrawer}
                onClose={() => setShowSortDrawer(false)}
                onSortChange={handleSortChange}
                currentSort={sortBy}
            />
            
            {/* Filter drawer component */}
            <FilterDrawer
                isVisible={showFilterDrawer}
                onClose={() => setShowFilterDrawer(false)}
                onFilterChange={handleFilterChange}
                currentFilters={activeFilters}
                thoughts={libraryThoughts}
            />
        </div>
    );
};

/**
 * Clean, minimal feed item with swipe functionality
 */
const FeedItem = ({ thought, isLast, onDelete }) => {
    const content = thought.thought || thought.content || '';
    const [swipeState, setSwipeState] = useState({ isOpen: false, direction: null });
    const navigate = useNavigate();
    
    const handleClick = () => {
        if (!swipeState.isOpen) {
            navigate(`/thought/${thought.id}`);
        }
    };
    
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setSwipeState({ isOpen: true, direction: 'left' }),
        onSwipedRight: () => setSwipeState({ isOpen: false, direction: 'right' }),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        delta: 10, // minimum swipe distance
        onSwiping: (eventData) => {
            // If horizontal movement is greater than vertical, prevent default to stop scrolling
            if (Math.abs(eventData.deltaX) > Math.abs(eventData.deltaY)) {
                eventData.event.preventDefault();
                eventData.event.stopPropagation();
            }
        }
    });
    
    // Add a touch handler to capture touch events at the start
    const handleTouchStart = (e) => {
        // We'll use this as a backup to ensure we can capture and prevent unwanted scrolling
        const initialTouch = e.touches[0];
        const touchStartX = initialTouch.clientX;
        const touchStartY = initialTouch.clientY;
        
        const handleTouchMove = (e) => {
            if (!e.touches.length) return;
            
            const touch = e.touches[0];
            const deltaX = touch.clientX - touchStartX;
            const deltaY = touch.clientY - touchStartY;
            
            // If horizontal movement is greater than vertical, prevent default
            if (Math.abs(deltaX) > Math.abs(deltaY) * 1.2) { // 1.2 multiplier for better detection
                e.preventDefault();
            }
        };
        
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        
        const cleanup = () => {
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', cleanup);
            document.removeEventListener('touchcancel', cleanup);
        };
        
        document.addEventListener('touchend', cleanup, { once: true });
        document.addEventListener('touchcancel', cleanup, { once: true });
    };
    
    // Check if thought has comments
    const hasComments = thought.comments && thought.comments.length > 0;
    
    // Check if we're in a collection path to determine button label
    const location = useLocation();
    const isInCollection = location.pathname.includes('/collection/');
    const isInDefaultCollection = location.pathname.includes('/collection/') && 
                                location.state && location.state.isDefault;
    const buttonLabel = isInCollection && !isInDefaultCollection ? "Remove" : "Delete";
    
    return (
        <div 
            style={{ 
                position: 'relative', 
                overflow: 'hidden',
                willChange: 'transform, opacity', // Optimize for animation performance
            }}
            className="list-item"
        >
            {/* Delete button (revealed when swiped left) */}
            <div 
                onClick={(e) => {
                    e.stopPropagation(); // Prevent bubbling up to the parent click handler
                    onDelete(); // Trigger the delete action
                    setSwipeState({ isOpen: false, direction: null }); // Reset swipe state
                }}
                style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '80px',
                    backgroundColor: '#FF3B30',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontWeight: '600',
                    cursor: 'pointer', // Add cursor pointer to indicate it's clickable
                }}
            >
                {buttonLabel}
            </div>
            
            {/* Main content */}
            <div 
                {...swipeHandlers}
                onTouchStart={handleTouchStart}
                onClick={handleClick}
                style={{ 
                    padding: '16px',
                    cursor: 'pointer',
                    width: '100%',
                    backgroundColor: colors.background || 'white',
                    borderBottom: isLast ? 'none' : `1px solid ${colors.divider}`,
                    transform: swipeState.isOpen ? 'translateX(-80px)' : 'translateX(0)',
                    transition: 'transform 0.3s cubic-bezier(0.2, 0, 0, 1)', // Improved transition curve
                    touchAction: 'pan-y', // Allow vertical scrolling by default
                }}
            >
                <div style={{
                    fontSize: '15px',
                    lineHeight: '1.5',
                    color: colors.textPrimary,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    position: 'relative',
                    paddingRight: (thought.importance === undefined || thought.importance === null) ? '16px' : '0',
                }}>
                    {content}
                    {hasComments && (
                        <span style={{ 
                            display: 'inline-flex',
                            alignItems: 'center',
                            marginLeft: '4px',
                            verticalAlign: 'middle' 
                        }}>
                            <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6h8" stroke={colors.textSecondary} strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M4 10h10" stroke={colors.textSecondary} strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M4 14h6" stroke={colors.textSecondary} strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </span>
                    )}
                    {(thought.importance === undefined || thought.importance === null) && (
                        <span style={{
                            position: 'absolute',
                            right: '0',
                            bottom: '3px',
                            width: '6px',
                            height: '6px',
                            borderRadius: '50%',
                            backgroundColor: colors.accent.blue,
                            display: 'inline-block',
                        }}></span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ThoughtsList;