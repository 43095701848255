import React, { useState, useEffect } from 'react';
import { colors } from '../styles/styles';
import CreateCollectionModal from './CreateCollectionModal';
import { getCollections, ensureDefaultCollection, deleteCollection } from '../services/collectionService';
import { useSwipeable } from 'react-swipeable';

/**
 * Library component that displays a list of thought collections
 */
const Library = ({ navigateToCollection }) => {
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [authError, setAuthError] = useState(false);
    
    // Fetch collections from Firebase on component mount
    useEffect(() => {
        fetchCollections();
    }, []);
    
    // Function to fetch collections (can be called after creating a new collection)
    const fetchCollections = async () => {
        setLoading(true);
        setAuthError(false);
        
        try {
            // Ensure the default collection exists
            await ensureDefaultCollection();
            
            // Fetch all collections
            const fetchedCollections = await getCollections();
            setCollections(fetchedCollections);
        } catch (error) {
            console.error('Error fetching collections:', error);
            // Check if this is an authentication error
            if (error.message && error.message.includes('not authenticated')) {
                setAuthError(true);
            }
            // Handle the error appropriately - at minimum show an empty state
            setCollections([]);
        } finally {
            setLoading(false);
        }
    };

    // Handle showing the create collection modal
    const handleCreateClick = () => {
        setShowCreateModal(true);
    };
    
    // Handle collection creation success
    const handleCollectionCreated = () => {
        fetchCollections(); // Refresh the collections
    };

    // Handle clicking on a collection with fade out animation
    const handleCollectionClick = (collection) => {
        // Simply call the navigation function from props - AppContent will handle the animation
        navigateToCollection(collection.id, collection.name, collection.isDefault);
    };

    // Handle deleting a collection
    const handleDeleteCollection = async (collectionId) => {
        try {
            await deleteCollection(collectionId);
            // Update the local state to remove the deleted collection
            setCollections(prevCollections => 
                prevCollections.filter(collection => collection.id !== collectionId)
            );
        } catch (error) {
            console.error('Error deleting collection:', error);
            alert('Failed to delete collection. Please try again.');
        }
    };

    // Sort collections to ensure "Your Thoughts" is always at the top
    const getSortedCollections = () => {
        if (!collections.length) return [];
        
        // Separate default collection and other collections
        const defaultCollection = collections.find(c => c.isDefault);
        const otherCollections = collections.filter(c => !c.isDefault);
        
        // Sort other collections by most recently updated
        const sortedCollections = otherCollections.sort((a, b) => {
            const dateA = a.updatedAt ? new Date(a.updatedAt.toDate()) : new Date(0);
            const dateB = b.updatedAt ? new Date(b.updatedAt.toDate()) : new Date(0);
            return dateB - dateA;
        });
        
        // Return with default collection at the top if it exists
        return defaultCollection ? [defaultCollection, ...sortedCollections] : sortedCollections;
    };

    // Add an authentication error state
    if (authError) {
        return (
            <div style={{ textAlign: 'center', padding: '40px 0', color: colors.textSecondary }}>
                <p>You need to be signed in to access collections.</p>
                <button
                    onClick={() => window.location.reload()} // Simple reload to trigger auth
                    style={{
                        backgroundColor: colors.accent.blue,
                        color: 'white',
                        border: 'none',
                        padding: '10px 16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        marginTop: '16px',
                        cursor: 'pointer'
                    }}
                >
                    Sign In
                </button>
            </div>
        );
    }

    // Get sorted collections
    const sortedCollections = getSortedCollections();

    return (
        <div className="library-container smooth-scroll" style={{ width: '100%' }}>
            {/* Remove all animation keyframes since we're using the one from AppContent */}
            <style>
                {`
                    @keyframes fadeIn {
                        from { opacity: 0; transform: translateY(10px); }
                        to { opacity: 1; transform: translateY(0); }
                    }
                    
                    .collections-feed > div {
                        opacity: 0;
                        animation-name: fadeIn;
                        animation-duration: 0.3s;
                        animation-fill-mode: forwards;
                        animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
                    }
                    
                    .collections-feed > div:nth-child(1) { animation-delay: 0.05s; }
                    .collections-feed > div:nth-child(2) { animation-delay: 0.1s; }
                    .collections-feed > div:nth-child(3) { animation-delay: 0.15s; }
                    .collections-feed > div:nth-child(4) { animation-delay: 0.2s; }
                    .collections-feed > div:nth-child(5) { animation-delay: 0.25s; }
                    .collections-feed > div:nth-child(6) { animation-delay: 0.3s; }
                    .collections-feed > div:nth-child(7) { animation-delay: 0.35s; }
                    .collections-feed > div:nth-child(8) { animation-delay: 0.4s; }
                    .collections-feed > div:nth-child(9) { animation-delay: 0.45s; }
                    .collections-feed > div:nth-child(10) { animation-delay: 0.5s; }
                    .collections-feed > div:nth-child(n+11) { animation-delay: 0.5s; }
                `}
            </style>
            
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '24px',
                padding: '0 16px',
                height: '38px' // Standardized header height
            }}>
                <h2 style={{
                    margin: 0,
                    fontSize: '22px',
                    fontWeight: '600',
                    color: colors.textPrimary,
                    lineHeight: '38px', // Match the container height
                }}>
                    Library
                </h2>
                <button
                    onClick={handleCreateClick}
                    style={{
                        background: 'none',
                        border: 'none',
                        padding: '4px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '32px',
                        height: '32px'
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 5V19M5 12H19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="butt" strokeLinejoin="miter"/>
                    </svg>
                </button>
            </div>
            
            {/* Render collections or empty state */}
            {!loading && collections.length === 0 ? (
                <div style={{ textAlign: 'center', padding: '20px', color: colors.textSecondary }}>
                    No collections found. Create your first collection.
                </div>
            ) : !loading && (
                <div className="collections-feed" style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    width: '100%',
                }}>
                    {sortedCollections.map((collection, index) => (
                        <CollectionItem 
                            key={collection.id}
                            collection={collection}
                            isLast={index === sortedCollections.length - 1}
                            onClick={() => handleCollectionClick(collection)}
                            onDelete={!collection.isDefault ? () => handleDeleteCollection(collection.id) : null}
                        />
                    ))}
                </div>
            )}
            
            {/* Create Collection Modal */}
            <CreateCollectionModal 
                isVisible={showCreateModal}
                onClose={() => setShowCreateModal(false)}
                onSuccess={handleCollectionCreated}
            />
        </div>
    );
};

/**
 * CollectionItem component with swipe functionality
 */
const CollectionItem = ({ collection, isLast, onClick, onDelete }) => {
    const [swipeState, setSwipeState] = useState({ isOpen: false, direction: null });
    const [isDeleting, setIsDeleting] = useState(false);
    
    const handleDelete = async (e) => {
        e.stopPropagation();
        if (!onDelete) return; // Do nothing if no delete handler provided
        
        setIsDeleting(true);
        try {
            await onDelete();
        } finally {
            setIsDeleting(false);
            setSwipeState({ isOpen: false, direction: null });
        }
    };
    
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => onDelete && setSwipeState({ isOpen: true, direction: 'left' }),
        onSwipedRight: () => setSwipeState({ isOpen: false, direction: 'right' }),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        delta: 10,
        onSwiping: (eventData) => {
            if (Math.abs(eventData.deltaX) > Math.abs(eventData.deltaY)) {
                eventData.event.preventDefault();
                eventData.event.stopPropagation();
            }
        }
    });
    
    const handleTouchStart = (e) => {
        if (!onDelete) return; // Skip if not deletable
        
        const initialTouch = e.touches[0];
        const touchStartX = initialTouch.clientX;
        const touchStartY = initialTouch.clientY;
        
        const handleTouchMove = (e) => {
            if (!e.touches.length) return;
            
            const touch = e.touches[0];
            const deltaX = touch.clientX - touchStartX;
            const deltaY = touch.clientY - touchStartY;
            
            if (Math.abs(deltaX) > Math.abs(deltaY) * 1.2) {
                e.preventDefault();
            }
        };
        
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        
        const cleanup = () => {
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', cleanup);
            document.removeEventListener('touchcancel', cleanup);
        };
        
        document.addEventListener('touchend', cleanup, { once: true });
        document.addEventListener('touchcancel', cleanup, { once: true });
    };
    
    return (
        <div 
            style={{ 
                position: 'relative', 
                overflow: 'hidden',
                willChange: 'transform, opacity',
            }}
            className="collection-item"
        >
            {/* Delete button (revealed when swiped left) */}
            {onDelete && (
                <div 
                    onClick={handleDelete}
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: '80px',
                        backgroundColor: '#FF3B30',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontWeight: '600',
                        cursor: 'pointer',
                    }}
                >
                    {isDeleting ? 'Deleting...' : 'Delete'}
                </div>
            )}
            
            {/* Main content */}
            <div 
                {...(onDelete ? swipeHandlers : {})}
                onTouchStart={onDelete ? handleTouchStart : undefined}
                onClick={onClick}
                style={{ 
                    padding: '16px',
                    cursor: 'pointer',
                    backgroundColor: colors.background,
                    borderBottom: isLast ? 'none' : `1px solid ${colors.divider}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    transform: swipeState.isOpen ? 'translateX(-80px)' : 'translateX(0)',
                    transition: 'transform 0.3s cubic-bezier(0.2, 0, 0, 1)',
                    touchAction: 'pan-y',
                }}
            >
                <span style={{
                    fontSize: '15px',
                    lineHeight: '1.5',
                    color: colors.textPrimary,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                }}>
                    {collection.name}
                </span>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 18L15 12L9 6" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    );
};

export default Library; 