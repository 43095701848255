import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { colors, styles } from '../styles/styles';
import { addThoughtToCollection, getCollectionThoughts, removeThoughtFromCollection } from '../services/collectionService';
import SortDrawer from './SortDrawer';
import FilterDrawer from './FilterDrawer';

/**
 * Page for adding thoughts to a collection
 */
const AddToCollectionPage = ({ thoughts, navigateWithFade }) => {
    const { collectionId } = useParams();
    const location = useLocation();
    const collectionName = location.state?.collectionName || "Collection";
    const isDefault = location.state?.isDefault || false;
    const [selectedThoughtIds, setSelectedThoughtIds] = useState([]);
    const [existingThoughtIds, setExistingThoughtIds] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(true);
    
    // Search, sort and filter states
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('recent');
    const [showSortDrawer, setShowSortDrawer] = useState(false);
    const [showFilterDrawer, setShowFilterDrawer] = useState(false);
    const [activeFilters, setActiveFilters] = useState({ tags: [] });
    const [popularTags, setPopularTags] = useState([]);
    
    // Fetch existing thoughts in the collection
    useEffect(() => {
        const fetchExistingThoughts = async () => {
            setLoading(true);
            try {
                const collectionThoughts = await getCollectionThoughts(collectionId);
                setExistingThoughtIds(collectionThoughts.map(t => t.id));
            } catch (error) {
                console.error('Error fetching collection thoughts:', error);
                setExistingThoughtIds([]);
            } finally {
                setLoading(false);
            }
        };
        
        fetchExistingThoughts();
    }, [collectionId]);

    // Set already existing thoughts as selected
    useEffect(() => {
        if (!loading && existingThoughtIds.length > 0) {
            setSelectedThoughtIds(existingThoughtIds);
        }
    }, [existingThoughtIds, loading]);
    
    // All thoughts are available - we'll show checkmarks for ones already in collection
    const availableThoughts = thoughts;
    
    // Check if any filters are active
    const hasActiveFilters = activeFilters.tags.length > 0;
    
    // Filter based on active tag filters
    const tagFilteredThoughts = availableThoughts.filter(thought => {
        // If no filters are applied, show all thoughts
        if (activeFilters.tags.length === 0) {
            return true;
        }

        // Only show thoughts that have at least one matching tag
        return thought.tags && 
               Array.isArray(thought.tags) && 
               thought.tags.some(tag => activeFilters.tags.includes(tag));
    });

    // Then filter based on search term
    const filteredThoughts = tagFilteredThoughts.filter(thought => {
        if (!searchTerm) return true;
        
        const searchLower = searchTerm.toLowerCase();
        return (
            thought.thought?.toLowerCase().includes(searchLower) ||
            (thought.theme && thought.theme.toLowerCase().includes(searchLower)) ||
            (thought.motif && thought.motif.toLowerCase().includes(searchLower)) ||
            (thought.tags && thought.tags.some(tag => tag.toLowerCase().includes(searchLower)))
        );
    });
    
    // Handle thought selection
    const toggleThoughtSelection = (thoughtId) => {
        setSelectedThoughtIds(prev => {
            if (prev.includes(thoughtId)) {
                return prev.filter(id => id !== thoughtId);
            } else {
                return [...prev, thoughtId];
            }
        });
    };
    
    // Handle navigating back
    const handleBack = () => {
        navigateWithFade(`/collection/${collectionId}`, { 
            collectionName,
            isDefault 
        });
    };
    
    // Handle adding selected thoughts to the collection
    const handleAddThoughts = async () => {
        if (isSubmitting) return;
        
        setIsSubmitting(true);
        setSuccessMessage('');
        
        try {
            // Calculate thoughts to add and remove
            const thoughtsToAdd = selectedThoughtIds.filter(id => !existingThoughtIds.includes(id));
            const thoughtsToRemove = existingThoughtIds.filter(id => !selectedThoughtIds.includes(id));
            
            // Add new thoughts to the collection
            for (const thoughtId of thoughtsToAdd) {
                await addThoughtToCollection(collectionId, thoughtId);
            }
            
            // Remove unselected thoughts from the collection
            for (const thoughtId of thoughtsToRemove) {
                await removeThoughtFromCollection(collectionId, thoughtId);
            }
            
            // Show appropriate success message
            if (thoughtsToAdd.length > 0 || thoughtsToRemove.length > 0) {
                let message = '';
                if (thoughtsToAdd.length > 0) {
                    message += `Added ${thoughtsToAdd.length} thought${thoughtsToAdd.length !== 1 ? 's' : ''}`;
                }
                if (thoughtsToRemove.length > 0) {
                    if (message) message += ' and ';
                    message += `Removed ${thoughtsToRemove.length} thought${thoughtsToRemove.length !== 1 ? 's' : ''}`;
                }
                setSuccessMessage(message);
            } else {
                setSuccessMessage('No changes to save');
            }
            
            // Navigate back after a short delay
            setTimeout(() => {
                navigateWithFade(`/collection/${collectionId}`, { 
                    collectionName,
                    isDefault 
                });
            }, 1000);
        } catch (error) {
            console.error('Error updating collection:', error);
            setSuccessMessage('Failed to update collection. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
    
    // Calculate popular tags on component mount or when thoughts change
    useEffect(() => {
        // Extract and count all tags
        const tagCounts = {};
        thoughts.forEach(thought => {
            if (thought.tags && Array.isArray(thought.tags)) {
                thought.tags.forEach(tag => {
                    tagCounts[tag] = (tagCounts[tag] || 0) + 1;
                });
            }
        });
        
        // Convert to array and sort by count (descending)
        const sortedTags = Object.entries(tagCounts)
            .sort((a, b) => b[1] - a[1])
            .map(([tag, count]) => ({ tag, count }))
            .slice(0, 50); // Top 50 tags
        
        setPopularTags(sortedTags);
    }, [thoughts]);

    // Toggle tag selection for filters
    const toggleTag = (tag) => {
        setActiveFilters(prev => {
            const isTagActive = prev.tags.includes(tag);
            
            if (isTagActive) {
                // Remove tag if already active
                return {
                    ...prev,
                    tags: prev.tags.filter(t => t !== tag)
                };
            } else {
                // Add tag if not active
                return {
                    ...prev,
                    tags: [...prev.tags, tag]
                };
            }
        });
    };
    
    // Handle sort change
    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
    };

    // Handle filter change
    const handleFilterChange = (newFilters) => {
        setActiveFilters(newFilters);
    };
    
    // Sort thoughts based on selected criteria
    const sortedThoughts = [...filteredThoughts].sort((a, b) => {
        if (sortBy === 'recent') {
            // Sort by timestamp (newest first)
            return new Date(b.timestamp) - new Date(a.timestamp);
        } else if (sortBy === 'importance') {
            // Sort by importance (highest first), then by timestamp for equal importance
            const importanceA = a.importance !== undefined ? a.importance : 0;
            const importanceB = b.importance !== undefined ? b.importance : 0;
            
            if (importanceB !== importanceA) {
                return importanceB - importanceA;
            } else {
                // Secondary sort by timestamp
                return new Date(b.timestamp) - new Date(a.timestamp);
            }
        }
        return 0;
    });
    
    return (
        <div style={{ width: '100%' }} className="smooth-scroll">
            {/* Remove fade animation styles since AppContent handles transitions */}
            <style>
                {`
                    .thoughts-feed > div {
                        opacity: 0;
                        animation-name: fadeIn;
                        animation-duration: 0.3s;
                        animation-fill-mode: forwards;
                        animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
                    }
                    
                    .thoughts-feed > div:nth-child(1) { animation-delay: 0.05s; }
                    .thoughts-feed > div:nth-child(2) { animation-delay: 0.1s; }
                    .thoughts-feed > div:nth-child(3) { animation-delay: 0.15s; }
                    .thoughts-feed > div:nth-child(4) { animation-delay: 0.2s; }
                    .thoughts-feed > div:nth-child(5) { animation-delay: 0.25s; }
                    .thoughts-feed > div:nth-child(6) { animation-delay: 0.3s; }
                    .thoughts-feed > div:nth-child(7) { animation-delay: 0.35s; }
                    .thoughts-feed > div:nth-child(8) { animation-delay: 0.4s; }
                    .thoughts-feed > div:nth-child(9) { animation-delay: 0.45s; }
                    .thoughts-feed > div:nth-child(10) { animation-delay: 0.5s; }
                    .thoughts-feed > div:nth-child(n+11) { animation-delay: 0.5s; }
                `}
            </style>
            
            {/* Header with back button and title */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                padding: '0 16px',
                height: '38px'
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px'
                }}>
                    <button 
                        onClick={handleBack}
                        style={{
                            background: 'none',
                            border: 'none',
                            padding: '4px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 18L9 12L15 6" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
            
            {/* Search and tools row - DESKTOP */}
            <div className="desktop-layout" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                padding: '0 16px',
                height: '38px'
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    maxWidth: '500px',
                    width: '60%',
                    height: '38px'
                }}>
                    {/* Search bar */}
                    <div style={{
                        position: 'relative',
                        flex: 1,
                        height: '38px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search thoughts..."
                            style={{
                                ...styles.input,
                                padding: '8px 12px 8px 36px',
                                fontSize: '14px',
                                borderRadius: '20px',
                                backgroundColor: colors.surfaceElevated,
                                border: 'none',
                                color: colors.textPrimary,
                                width: '100%',
                                height: '32px',
                            }}
                        />
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                position: 'absolute',
                                left: '12px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: colors.textSecondary
                            }}
                        >
                            <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    
                    {/* Tools */}
                    <div style={{ 
                        display: 'flex', 
                        gap: '4px',
                        height: '38px',
                        alignItems: 'center'
                    }}>
                        {/* Sort button */}
                        <button
                            onClick={() => setShowSortDrawer(true)}
                            style={{
                                background: 'none',
                                border: 'none',
                                padding: '4px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '32px',
                                height: '32px'
                            }}
                        >
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6H14M4 12H11M4 18H8M17 8L20 5M20 5L23 8M20 5V19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        
                        {/* Filter button */}
                        <button
                            onClick={() => setShowFilterDrawer(true)}
                            aria-label="Filter"
                            style={{
                                background: hasActiveFilters ? `${colors.accent.blue}20` : 'none',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '4px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '32px',
                                height: '32px',
                                position: 'relative'
                            }}
                        >
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke={hasActiveFilters ? colors.accent.blue : colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            
                            {/* Indicator dot for active filters */}
                            {hasActiveFilters && (
                                <span style={{
                                    position: 'absolute',
                                    top: '-2px',
                                    right: '-2px',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    backgroundColor: colors.accent.blue,
                                }}></span>
                            )}
                        </button>
                    </div>
                </div>
                
                <span style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: colors.textSecondary,
                    lineHeight: '38px',
                }}>
                    {selectedThoughtIds.length} selected
                </span>
            </div>
            
            {/* Horizontal scrollable tag filters */}
            <div style={{
                height: popularTags.length > 0 ? 'auto' : '62px', /* Reserve space even when empty */
                overflowX: popularTags.length > 0 ? 'auto' : 'hidden',
                whiteSpace: 'nowrap',
                padding: '8px 16px',
                marginTop: '24px',
                marginBottom: '8px',
                WebkitOverflowScrolling: 'touch',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}>
                <style>
                    {`
                        /* Hide scrollbar for Chrome/Safari */
                        div::-webkit-scrollbar {
                            display: none;
                        }
                    `}
                </style>
                <div className="fade-in" style={{
                    display: 'inline-flex',
                    gap: '8px',
                    paddingBottom: '4px',
                    opacity: popularTags.length > 0 ? 1 : 0,
                    transition: 'opacity 0.3s ease'
                }}>
                    {popularTags.map(({ tag }) => {
                        const isActive = activeFilters.tags.includes(tag);
                        return (
                            <button
                                key={tag}
                                onClick={() => toggleTag(tag)}
                                style={{
                                    backgroundColor: isActive ? colors.accent.blue : `${colors.accent.blue}10`,
                                    color: isActive ? 'white' : colors.accent.blue,
                                    border: 'none',
                                    borderRadius: '16px',
                                    padding: '8px 14px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    transition: 'all 0.2s cubic-bezier(0.2, 0, 0, 1)',
                                }}
                            >
                                {tag}
                            </button>
                        );
                    })}
                        
                    {/* Clear All button - only visible when there are active filters */}
                    {activeFilters.tags.length > 0 && (
                        <button 
                            onClick={() => setActiveFilters({ tags: [] })}
                            style={{
                                backgroundColor: 'rgba(255, 59, 48, 0.1)',
                                color: '#FF3B30',
                                border: 'none',
                                borderRadius: '16px',
                                padding: '8px 14px',
                                fontSize: '14px',
                                fontWeight: '500',
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                transition: 'all 0.2s cubic-bezier(0.2, 0, 0, 1)',
                            }}
                        >
                            Clear All
                        </button>
                    )}
                </div>
            </div>
            
            {/* Content */}
            <div style={{ width: '100%' }}>
                {successMessage ? (
                    <div style={{ 
                        textAlign: 'center', 
                        padding: '20px', 
                        color: successMessage.includes('Failed') ? colors.error : colors.accent.green,
                        fontWeight: '500'
                    }}>
                        {successMessage}
                    </div>
                ) : (searchTerm || hasActiveFilters) && sortedThoughts.length === 0 ? (
                    <div style={{ 
                        textAlign: 'center', 
                        padding: '40px 0', 
                        color: colors.textSecondary,
                        marginTop: '16px'
                    }}>
                        <p>No thoughts match your search or filters.</p>
                    </div>
                ) : (
                    <div className="thoughts-feed" style={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        marginBottom: '80px' // Space for the footer
                    }}>
                        {sortedThoughts.map((thought, index) => (
                            <div 
                                key={thought.id}
                                onClick={() => toggleThoughtSelection(thought.id)}
                                className="list-item"
                                style={{ 
                                    padding: '16px',
                                    cursor: 'pointer',
                                    width: '100%',
                                    backgroundColor: colors.background,
                                    borderBottom: index === sortedThoughts.length - 1 ? 'none' : `1px solid ${colors.divider}`,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {/* Checkmark indicator */}
                                <div style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '12px',
                                    marginTop: '2px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexShrink: 0
                                }}>
                                    {selectedThoughtIds.includes(thought.id) && (
                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6L9 17L4 12" stroke={colors.accent.blue} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    )}
                                </div>
                                
                                {/* Thought content */}
                                <div style={{
                                    flex: 1,
                                    fontSize: '15px',
                                    lineHeight: '1.5',
                                    color: colors.textPrimary,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                }}>
                                    {thought.thought || ''}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                
                {/* Footer with action buttons */}
                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '16px',
                    backgroundColor: colors.surfaceElevated,
                    borderTop: `1px solid ${colors.divider}`,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    zIndex: 10
                }}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <button
                            onClick={handleBack}
                            style={{
                                backgroundColor: 'transparent',
                                color: colors.accent.blue,
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                cursor: 'pointer'
                            }}
                        >
                            Cancel
                        </button>
                        
                        <button
                            onClick={handleAddThoughts}
                            disabled={selectedThoughtIds.length === 0 || isSubmitting}
                            style={{
                                backgroundColor: colors.accent.blue,
                                color: 'white',
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                cursor: (selectedThoughtIds.length > 0 && !isSubmitting) ? 'pointer' : 'not-allowed',
                                opacity: (selectedThoughtIds.length > 0 && !isSubmitting) ? 1 : 0.5
                            }}
                        >
                            {isSubmitting ? 'Updating...' : 'Save'}
                        </button>
                    </div>
                </div>
                
                {/* Sort drawer component */}
                <SortDrawer
                    isVisible={showSortDrawer}
                    onClose={() => setShowSortDrawer(false)}
                    onSortChange={handleSortChange}
                    currentSort={sortBy}
                />
                
                {/* Filter drawer component */}
                <FilterDrawer
                    isVisible={showFilterDrawer}
                    onClose={() => setShowFilterDrawer(false)}
                    onFilterChange={handleFilterChange}
                    currentFilters={activeFilters}
                    thoughts={availableThoughts}
                />
            </div>
        </div>
    );
};

export default AddToCollectionPage; 