import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { colors, styles } from '../styles/styles';
import { 
  addThoughtToCollection, 
  removeThoughtFromCollection, 
  getCollections 
} from '../services/collectionService';

/**
 * Page for adding a single thought to multiple collections
 */
const AddToCollectionsPage = ({ navigateWithFade }) => {
    const { thoughtId } = useParams();
    const location = useLocation();
    const thoughtText = location.state?.thoughtText || "Thought";
    
    const [collections, setCollections] = useState([]);
    const [selectedCollectionIds, setSelectedCollectionIds] = useState([]);
    const [existingCollectionIds, setExistingCollectionIds] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    
    // Fetch all collections and existing collections for this thought
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Get all collections
                const allCollections = await getCollections();
                setCollections(allCollections);
                
                // Get existing collection ids from state
                if (location.state?.existingCollectionIds) {
                    setExistingCollectionIds(location.state.existingCollectionIds);
                    setSelectedCollectionIds(location.state.existingCollectionIds);
                }
            } catch (error) {
                console.error('Error fetching collections:', error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, [location.state, thoughtId]);
    
    // Filter collections based on search term
    const filteredCollections = collections.filter(collection => {
        if (!searchTerm) return true;
        
        const searchLower = searchTerm.toLowerCase();
        return collection.name.toLowerCase().includes(searchLower);
    });
    
    // Handle collection selection
    const toggleCollectionSelection = (collectionId) => {
        setSelectedCollectionIds(prev => {
            if (prev.includes(collectionId)) {
                return prev.filter(id => id !== collectionId);
            } else {
                return [...prev, collectionId];
            }
        });
    };
    
    // Handle navigating back
    const handleBack = () => {
        navigateWithFade(`/thought/${thoughtId}`);
    };
    
    // Handle saving collection selections
    const handleSaveCollections = async () => {
        if (isSubmitting) return;
        
        setIsSubmitting(true);
        setSuccessMessage('');
        
        try {
            // Calculate collections to add and remove
            const collectionsToAdd = selectedCollectionIds.filter(id => !existingCollectionIds.includes(id));
            const collectionsToRemove = existingCollectionIds.filter(id => !selectedCollectionIds.includes(id));
            
            // Add thought to new collections
            for (const collectionId of collectionsToAdd) {
                await addThoughtToCollection(collectionId, thoughtId);
            }
            
            // Remove thought from unselected collections
            for (const collectionId of collectionsToRemove) {
                await removeThoughtFromCollection(collectionId, thoughtId);
            }
            
            // Show appropriate success message
            if (collectionsToAdd.length > 0 || collectionsToRemove.length > 0) {
                let message = '';
                if (collectionsToAdd.length > 0) {
                    message += `Added to ${collectionsToAdd.length} collection${collectionsToAdd.length !== 1 ? 's' : ''}`;
                }
                if (collectionsToRemove.length > 0) {
                    if (message) message += ' and ';
                    message += `Removed from ${collectionsToRemove.length} collection${collectionsToRemove.length !== 1 ? 's' : ''}`;
                }
                setSuccessMessage(message);
            } else {
                setSuccessMessage('No changes to save');
            }
            
            // Navigate back after a short delay
            setTimeout(() => {
                navigateWithFade(`/thought/${thoughtId}`);
            }, 1000);
        } catch (error) {
            console.error('Error updating collections:', error);
            setSuccessMessage('Failed to update collections. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
    
    return (
        <div style={{ width: '100%' }} className="smooth-scroll">
            {/* Remove fade animation styles since AppContent handles transitions */}
            <style>
                {`
                    .collections-feed > div {
                        opacity: 0;
                        animation-name: fadeIn;
                        animation-duration: 0.3s;
                        animation-fill-mode: forwards;
                        animation-timing-function: cubic-bezier(0.2, 0, 0, 1);
                    }
                    
                    .collections-feed > div:nth-child(1) { animation-delay: 0.05s; }
                    .collections-feed > div:nth-child(2) { animation-delay: 0.1s; }
                    .collections-feed > div:nth-child(3) { animation-delay: 0.15s; }
                    .collections-feed > div:nth-child(4) { animation-delay: 0.2s; }
                    .collections-feed > div:nth-child(5) { animation-delay: 0.25s; }
                    .collections-feed > div:nth-child(6) { animation-delay: 0.3s; }
                    .collections-feed > div:nth-child(7) { animation-delay: 0.35s; }
                    .collections-feed > div:nth-child(8) { animation-delay: 0.4s; }
                    .collections-feed > div:nth-child(9) { animation-delay: 0.45s; }
                    .collections-feed > div:nth-child(10) { animation-delay: 0.5s; }
                    .collections-feed > div:nth-child(n+11) { animation-delay: 0.5s; }
                `}
            </style>
            
            {/* Header with back button and title */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                padding: '0 16px',
                height: '38px'
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px'
                }}>
                    <button 
                        onClick={handleBack}
                        style={{
                            background: 'none',
                            border: 'none',
                            padding: '4px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 18L9 12L15 6" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <span style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: colors.textPrimary,
                    }}>
                        Add to Collections
                    </span>
                </div>
            </div>
            
            {/* Search row */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                padding: '0 16px',
                height: '38px'
            }}>
                <div style={{
                    position: 'relative',
                    flex: 1,
                    maxWidth: '500px',
                    height: '38px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search collections..."
                        style={{
                            ...styles.input,
                            padding: '8px 12px 8px 36px',
                            fontSize: '14px',
                            borderRadius: '20px',
                            backgroundColor: colors.surfaceElevated,
                            border: 'none',
                            color: colors.textPrimary,
                            width: '100%',
                            height: '32px',
                        }}
                    />
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            position: 'absolute',
                            left: '12px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: colors.textSecondary
                        }}
                    >
                        <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                
                <span style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: colors.textSecondary,
                    marginLeft: '16px',
                }}>
                    {selectedCollectionIds.length} selected
                </span>
            </div>
            
            {/* Thought preview */}
            <div style={{
                margin: '0 16px 16px',
                padding: '12px 16px',
                backgroundColor: colors.surfaceElevated,
                borderRadius: '12px',
                maxHeight: '80px',
                overflow: 'hidden',
                position: 'relative',
            }}>
                <p style={{
                    margin: 0,
                    fontSize: '14px',
                    lineHeight: '1.4',
                    color: colors.textPrimary,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                }}>
                    {thoughtText}
                </p>
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '20px',
                    background: `linear-gradient(to bottom, transparent, ${colors.surfaceElevated})`,
                }}></div>
            </div>
            
            {/* Content */}
            <div style={{ width: '100%' }}>
                {successMessage ? (
                    <div style={{ 
                        textAlign: 'center', 
                        padding: '20px', 
                        color: successMessage.includes('Failed') ? colors.error : colors.accent.green,
                        fontWeight: '500'
                    }}>
                        {successMessage}
                    </div>
                ) : loading ? (
                    <div style={{ 
                        textAlign: 'center', 
                        padding: '40px', 
                        color: colors.textSecondary 
                    }}>
                        Loading collections...
                    </div>
                ) : filteredCollections.length === 0 ? (
                    <div style={{ 
                        textAlign: 'center', 
                        padding: '40px 0', 
                        color: colors.textSecondary,
                        marginTop: '16px'
                    }}>
                        <p>No collections found</p>
                    </div>
                ) : (
                    <div className="collections-feed" style={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        marginBottom: '80px' // Space for the footer
                    }}>
                        {filteredCollections.map((collection, index) => (
                            <div 
                                key={collection.id}
                                onClick={() => toggleCollectionSelection(collection.id)}
                                className="list-item"
                                style={{ 
                                    padding: '16px',
                                    cursor: 'pointer',
                                    width: '100%',
                                    backgroundColor: colors.background,
                                    borderBottom: index === filteredCollections.length - 1 ? 'none' : `1px solid ${colors.divider}`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span style={{
                                    fontSize: '15px',
                                    lineHeight: '1.5',
                                    color: colors.textPrimary,
                                    flex: 1,
                                }}>
                                    {collection.name}
                                    {collection.isDefault && (
                                        <span style={{
                                            color: colors.textSecondary,
                                            fontSize: '13px',
                                            marginLeft: '8px',
                                        }}>
                                            (default)
                                        </span>
                                    )}
                                </span>
                                
                                {/* Checkmark indicator */}
                                <div style={{
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '12px',
                                    backgroundColor: selectedCollectionIds.includes(collection.id) ? colors.accent.blue : 'transparent',
                                    border: selectedCollectionIds.includes(collection.id) ? 'none' : `1px solid ${colors.divider}`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {selectedCollectionIds.includes(collection.id) && (
                                        <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 6L9 17L4 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                
                {/* Footer with action buttons */}
                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '16px',
                    backgroundColor: colors.surfaceElevated,
                    borderTop: `1px solid ${colors.divider}`,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    zIndex: 10
                }}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <button
                            onClick={handleBack}
                            style={{
                                backgroundColor: 'transparent',
                                color: colors.accent.blue,
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                cursor: 'pointer'
                            }}
                        >
                            Cancel
                        </button>
                        
                        <button
                            onClick={handleSaveCollections}
                            disabled={isSubmitting}
                            style={{
                                backgroundColor: colors.accent.blue,
                                color: 'white',
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                cursor: !isSubmitting ? 'pointer' : 'not-allowed',
                                opacity: !isSubmitting ? 1 : 0.5
                            }}
                        >
                            {isSubmitting ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddToCollectionsPage; 