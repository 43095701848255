import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Tabs from './Tabs';
import Library from './Library';
import InboxList from './InboxList';
import ThoughtDetail from './ThoughtDetail';
import ComposePage from './ComposePage';
import FieldEditPage from './FieldEditPage';
import FloatingActionButton from './FloatingActionButton';
import ThoughtsList from './ThoughtsList';
import AddToCollectionPage from './AddToCollectionPage';
import AddToCollectionsPage from './AddToCollectionsPage';
import { getCollectionThoughts } from '../services/collectionService';

const AppContent = ({ activeTab, setActiveTab, thoughts, saveThought, deleteThought, updateThoughtLocally, themes, motifs, tags, isDataLoading }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);
  
  // Check if on detail page (to remove padding when header is hidden)
  const isDetailPage = location.pathname.includes('/thought/') || 
                      location.pathname.includes('/edit/') ||
                      location.pathname.includes('/edit-field/') ||
                      location.pathname.includes('/compose') ||
                      location.pathname.includes('/collection/');
  
  // Check screen size for responsive behavior
  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Filter thoughts for the inbox view (only thoughts with no theme, motif, or tags)
  const inboxThoughts = thoughts.filter(thought => 
    (!thought.theme || thought.theme === '') && 
    (!thought.motif || thought.motif === '') && 
    (!thought.tags || thought.tags.length === 0)
  );

  // Handle deleting a thought
  const handleDeleteThought = (thoughtId) => {
    // Use the deleteThought function passed from App.js
    if (deleteThought && thoughtId) {
      deleteThought(thoughtId);
    }
  };

  // Handle navigation with fade effect
  const handleNavigateWithFade = (to, state = {}) => {
    if (isAnimating) return; // Prevent multiple animations
    setIsAnimating(true);
    
    // Fix for thought IDs - if the path is just an ID (no slashes), add the /thought/ prefix
    if (to && !to.includes('/')) {
      to = `/thought/${to}`;
    }
    
    // Apply fade out animation
    document.body.classList.add('fade-out');
    
    // Navigate after animation completes
    setTimeout(() => {
      navigate(to, { state });
      document.body.classList.remove('fade-out');
      setIsAnimating(false);
    }, 180); // Match the animation duration
  };

  // Handle back to library with fade
  const handleBackToLibrary = () => {
    handleNavigateWithFade('/');
    setActiveTab("library");
  };

  return (
    <>
      <style>
        {`
          .fade-out {
            animation: fadeOut 0.18s cubic-bezier(0.2, 0, 0, 1) forwards;
          }
          
          @keyframes fadeOut {
            from { opacity: 1; }
            to { opacity: 0; }
          }
        `}
      </style>
      <div 
        className="main-content-container"
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          paddingTop: isDetailPage ? '0' : '60px', // Only add padding when header is shown
          marginTop: 0,
        }}
      >
        
        {/* Main content area - dynamically changes based on active tab */}
        <div style={{
          flex: 1,
          overflow: 'auto',
          padding: isMobile ? '8px' : '16px 24px',
          paddingBottom: '80px', // Extra padding for FAB
        }}>
          <Routes>
            <Route path="/" element={
              <>
                {/* Tabs for all devices */}
                <Tabs
                  activeTab={activeTab}
                  onTabChange={setActiveTab}
                  inboxCount={inboxThoughts.length}
                />

                {/* Content based on active tab */}
                {activeTab === "inbox" ? (
                  <InboxList 
                    thoughts={inboxThoughts} 
                    emptyMessage="Enjoy the mental pause"
                    onDeleteThought={handleDeleteThought}
                    onNavigate={handleNavigateWithFade}
                  />
                ) : (
                  <Library 
                    navigateToCollection={(collectionId, collectionName, isDefault) => 
                      handleNavigateWithFade(`/collection/${collectionId}`, { 
                        collectionName,
                        isDefault 
                      })
                    }
                  />
                )}
              </>
            } />
            <Route path="/thought/:id" element={
              <ThoughtDetail 
                thoughts={thoughts}
                onSave={saveThought}
                updateThoughtLocally={updateThoughtLocally}
                isDataLoading={isDataLoading}
              />
            } />
            <Route path="/edit/:id" element={
              <ComposePage 
                thoughts={thoughts}
                onSave={saveThought}
                updateThoughtLocally={updateThoughtLocally}
                isDataLoading={isDataLoading}
                isEditing={true}
              />
            } />
            <Route path="/edit-field/:id/:fieldName" element={
              <FieldEditPage 
                thoughts={thoughts}
                onSave={saveThought}
                themes={themes}
                motifs={motifs}
                tags={tags}
                isDataLoading={isDataLoading}
              />
            } />
            <Route path="/compose" element={
              <ComposePage 
                thoughts={thoughts}
                onSave={saveThought}
                updateThoughtLocally={updateThoughtLocally}
                isDataLoading={isDataLoading}
              />
            } />
            <Route path="/thought/:thoughtId/collections" element={
              <AddToCollectionsPage 
                navigateWithFade={handleNavigateWithFade}
              />
            } />
            <Route path="/collection/:collectionId" element={
              <CollectionView 
                thoughts={thoughts} 
                onDeleteThought={handleDeleteThought} 
                onBackClick={handleBackToLibrary}
                navigateWithFade={handleNavigateWithFade}
              />
            } />
            <Route path="/collection/:collectionId/add" element={
              <AddToCollectionPage 
                thoughts={thoughts}
                navigateWithFade={handleNavigateWithFade}
              />
            } />
          </Routes>
        </div>
        
        {/* Floating Action Button (not on detail pages) */}
        {!isDetailPage && (
          <FloatingActionButton onClick={() => handleNavigateWithFade('/compose')} />
        )}
      </div>
    </>
  );
};

// Collection View Component - Renders a collection without tabs
const CollectionView = ({ thoughts, onDeleteThought, onBackClick, navigateWithFade }) => {
  const location = useLocation();
  const { state } = location;
  
  // Extract collection ID from the URL
  const collectionId = location.pathname.split('/collection/')[1];
  const collectionName = state?.collectionName || "Collection";
  const isDefault = state?.isDefault || false;
  
  const [collectionThoughts, setCollectionThoughts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  
  // Get collection thoughts
  useEffect(() => {
    const fetchCollectionThoughts = async () => {
      setLoading(true);
      try {
        // If this is the default collection, just use all thoughts
        if (isDefault) {
          setCollectionThoughts(thoughts);
          setLoading(false);
          return;
        }
        
        // Otherwise fetch the collection thoughts
        const fetchedThoughts = await getCollectionThoughts(collectionId);
        setCollectionThoughts(fetchedThoughts);
      } catch (error) {
        console.error('Error fetching collection thoughts:', error);
        setCollectionThoughts([]);
      } finally {
        setLoading(false);
      }
    };
    
    fetchCollectionThoughts();
  }, [collectionId, isDefault, thoughts]);
  
  // Handle back with animation
  const handleBack = () => {
    onBackClick();
  };
  
  // Handle adding thoughts to collection
  const handleAddToCollection = () => {
    navigateWithFade(`/collection/${collectionId}/add`, {
      collectionName,
      isDefault
    });
  };
  
  // Handle thought deletion - differently for default vs. custom collections
  const handleDeleteThought = async (thoughtId) => {
    if (isDefault) {
      // For the default collection, actually delete the thought from database
      onDeleteThought(thoughtId);
    } else {
      try {
        // For non-default collections, just remove it from the collection
        const { removeThoughtFromCollection } = await import('../services/collectionService');
        await removeThoughtFromCollection(collectionId, thoughtId);
        
        // Update local state to remove the thought from the displayed list
        setCollectionThoughts(prevThoughts => 
          prevThoughts.filter(thought => thought.id !== thoughtId)
        );
      } catch (error) {
        console.error('Error removing thought from collection:', error);
      }
    }
  };
  
  // Show loading state while fetching collection thoughts
  if (isLoading) {
    return (
      <div style={{ 
        padding: '40px', 
        textAlign: 'center', 
        color: 'rgba(0,0,0,0.6)' 
      }}>
        Loading collection...
      </div>
    );
  }
  
  return (
    <div className="collection-view-container" style={{ width: '100%' }}>
      <ThoughtsList
        thoughts={collectionThoughts}
        title={collectionName}
        onDeleteThought={handleDeleteThought}
        onBackClick={handleBack}
        showBackButton={true}
        onAddClick={!isDefault ? handleAddToCollection : undefined}
      />
    </div>
  );
};

export default AppContent; 